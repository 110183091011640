import React, { useEffect, useState } from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./Faculty.css";
import setting from "../../setting.json";
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";

function Deled() {

    const [faculty, setFaculty] = useState('');

    const getAllNews = async () => {
        await fetch(setting.api + "/api/getAllFaculty", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setFaculty(u.data);

            });
    }

    useEffect(() => {
        getAllNews();
    }, []);


    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold">D.EL.ED. Faculty</h1>
                </div>
            </header>

            <section className="teacher-section py-3 bg_img ">
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="section-header">
                                <h3 className="title" style={{ textAlign: "center" }}><b>Our Teachers</b></h3>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center text-light">

                        {faculty && faculty.map((f) =>

                            <div className="col-lg-2 col-md-2 col-sm-3 col-xs-4 teachers-bg mx-1 my-1 " style={{ backgroundColor: "rgba(131, 161, 218, .308)", color: "black" }}>
                                <img src={`${setting.api}uploads/faculty/${f.photo}`} className="col-12 teacher-img my-3 " alt="" />
                                <b className="teacher-name">{f.name}</b>
                                <br />
                                <span className=" teacher-edu">
                                    {f.course}
                                    <br />
                                    {f.subject}
                                </span>

                            </div>

                        )}

                    </div>

                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Deled;
