import React, { useState } from "react";
import "./TalentSearch.css";
import { Card } from 'react-bootstrap';
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import certificateImage from '../../images/certificate.jpg';
import setting from "../../setting.json";

function TalentSearchResult() {

  const [result, setResult] = useState(null);

  const searchResult = async () => {
    var rollno = document.getElementById("rollno").value;
    // console.log(rollno);
    var dt = {
      "rollno": rollno
    }
    await fetch(setting.api + "/api/getResultByRollNo", {
      method: "POST",
      body: JSON.stringify(dt),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(response => response.json())
      .then(res => {
        setResult(res.data);
      })
  }

  return (
    <div className="talent-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Results</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5" style={{ textAlign: "center" }}>
              <u>Talent Search Exam 2024</u>
            </h2>

            <Card
              className="h-100 w-100 shadow"
              style={{ backgroundColor: "#090989a4" }}
            >
              <Card.Body className="p-md-3" style={{ textAlign: "justify" }}>
                <Card.Title style={{ fontSize: "16px", textAlign: "center" }}>
                  <h3>Download Your Certificate</h3>
                </Card.Title>
                <Card.Text style={{ fontSize: "12px", textAlign: "center" }}>
                  Enter your Roll No. to check your result.
                  <br />
                  <br />
                  <div>
                    <input
                      type="text"
                      name=""
                      id="rollno"
                      style={{
                        width: "60%",
                        border: "solid black 1px",
                        borderRadius: "20px",
                      }}
                    />
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btn btn-danger btn-sm mx-0 mx-sm-2 my-2 my-sm-0"
                    style={{ borderRadius: "20px" }}
                    onClick={searchResult}
                  >
                    Search
                  </button>
                  <br />
                  <br />
                  <div
                    className="row mx-2 pt-2"
                    style={{
                      backgroundColor: "#9b9bad6c",
                      borderRadius: "20px",
                    }}
                  >
                    <p className="col-3 head">
                      <b>Roll No. :</b>
                    </p>
                    <p className="col-3 detail">{result && result.rollno}</p>
                    <p className="col-3 head">
                      <b>Certificate No. :</b>
                    </p>
                    <p className="col-3 detail">
                      {result && result.certificate_no}
                    </p>
                    <p className="col-3 head">
                      <b>Student Name :</b>
                    </p>
                    <p className="col-3 detail">
                      {result && result.student_name}
                    </p>
                    <p className="col-3 head">
                      <b>Father's Name :</b>
                    </p>
                    <p className="col-3 detail">
                      {result && result.father_name}
                    </p>
                    <p className="col-3 head">
                      <b>School Name :</b>
                    </p>
                    <p className="col-3 detail">
                      {result && result.school_name}
                    </p>
                    <p className="col-3 head">
                      <b>Score :</b>
                    </p>
                    <p className="col-3 detail">{result && result.score}</p>
                    <p className="col-3 head">
                      <b>Result :</b>
                    </p>
                    <p className="col-3 detail">{result && result.result}</p>
                    <p className="col-3 head">
                      <b>Prize :</b>
                    </p>
                    <p className="col-3 detail">{result && result.prize}</p>
                    <p className="col-3 head">
                      <b>Remark :</b>
                    </p>
                    <p className="col-3 detail">{result && result.remark}</p>
                  </div>
                  <br />
                  {result &&
                    result.absent !== "1" &&
                    result.result_status !== "0" ? (
                    <Link
                      className="btn btn-danger btn-sm mx-0 mx-sm-2 my-2 my-sm-0"
                      to={`/certificate/${result && result.rollno}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-printer-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1" />
                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                      </svg>
                    </Link>
                  ) : (
                    ""
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-6  justify-content-center">
            <br />
            <br />
            <p style={{ fontSize: "16px", textAlign: "center" }}>
              (Sample Certificate)
            </p>
            <img src={certificateImage} className="img-fluid w-100" alt="" />
          </div>
        </div>

        {/* <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h4>Instructions to download result</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              <br />
              <ul>
                <li>
                  The College aims at catering to the academic needs of the
                  students with priority to those belonging to economically and
                  socially weak families and with a stress on women's education.
                </li>
                <li>
                  The college aims at developing the personality of the
                  individuals so as to groom them into worthy citizens with an
                  in-depth faith in oneness of God and Universal Brotherhood.
                </li>
                <li>
                  The College aims at providing such teaching and tools to the
                  students, by way of introducing Vocational Courses, as may
                  help them develop their entrepreneurial skills and meet the
                  coming global challenges.
                </li>

                <li>
                  The College aims at sensitizing the students on socio-economic
                  issues with emphasis on gender and human rights as well as on
                  environmental issues through extension activities and also by
                  introducing the students to various co-curricular activities
                  such as sports and games, cultural activities and youth
                  festival, literary activities, seminar etc. so that they can
                  become liberal thinkers with democratic ideals.
                </li>

                <li>
                  The college aims at making use of ICT aided teaching so that
                  the students can be given exposure to the latest advancement
                  in technology.
                </li>
              </ul>
            </p>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default TalentSearchResult;
