import React from "react";
import "./Download.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import setting from "../../setting.json";
function Income() {

    const pdfPath = setting.api + '/uploads/docs/income_expenditure_2023.pdf';

    return (
        <div className="talent-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold">Income Expenditure 2023</h1>
                </div>
            </header>

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                        <h2 className="mb-4 mb-lg-5" style={{ textAlign: "center" }}>

                        </h2>

                    </div>

                    <div>

                        <iframe
                            src={pdfPath}
                            type="application/pdf"
                            width="100%"
                            height="500"
                        >

                        </iframe>
                    </div>

                </div>

            </div>
            <Footer />
        </div>
    );
}

export default Income;
