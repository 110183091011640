import React from 'react'
import './certificate.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import borderImage from "../../images/certificate-bg.webp";
import logoImage from '../../images/logo.png';
import managerSign from '../../images/manager2.png';
import principalSign from '../../images/principal.png';
import coordinatorSign from '../../images/coordinator.png';
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import setting from "../../setting.json";

function Certificate() {
  const { roll } = useParams();

  const [result, setResult] = useState(null);

  const [loading, setLoading] = useState(true);

  const print = async () => {
    var dt = {
      rollno: roll,
    };
    await fetch(setting.api + "/api/getResultByRollNo", {
      method: "POST",
      body: JSON.stringify(dt),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setResult(res.data);
      });
  };
  useEffect(() => {
    print();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      // window.print();
    }, 7000);
  }, [result]);

  return (
    <div className="main">
      <div className="overlay">
        {loading ? (
          <p className="loading">Loading...</p>
        ) : (
          <p>
            <Button className="loading" onClick={window.print}>
              Print
            </Button>
          </p>
        )}
      </div>
      <img src={borderImage} alt="" className="background" />
      <div className="cntnr">
        <div className="title">
          <img src={logoImage} alt="" />
          <div className="cname">
            <h1 className="acm-head">ACHARYA CHANAKYA MAHAVIDYALAYA</h1>
            <p className="address">(Mahmoodpur, Semari, Sultanpur)</p>
          </div>
        </div>
        <div>
          <h2 className="heading">Certificate of Participation</h2>
          <p className="p-num">
            (Certificate No.<b>{result && result.certificate_no}</b>)
          </p>
        </div>
        <div className="content">
          <p>This is to certify that</p>
          <p className="name">
            <b>{result && result.student_name}</b>
          </p>
          <p>has participated in</p>
          {/* <!-- <p><b>First Position</b> in</p> --> */}
          <p className="cName">
            <b>TALENT SEARCH COMPETITION 2024</b>
          </p>
          <p>held on 7th January, 2024</p>
          <p>And is therefore awarded this certificate</p>
        </div>
        <div className="bottom">
          <div className="sign">
            <img src={managerSign} alt="" />
            <div>
              <b>Dr. Om Prakash Tripathi</b>
            </div>
            <div>(Manager)</div>
          </div>
          <div className="sign">
            <img src={principalSign} alt="" />
            <div>
              <b>Dr B.P. Pandey</b>
            </div>
            <div>(Principal)</div>
          </div>
          <div className="sign">
            <img src={coordinatorSign} alt="" />
            <div>
              <b>Dr.Rajeev Pandey </b>
            </div>
            <div>(Exam Cordinator)</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certificate