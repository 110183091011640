import React, { useState, useEffect } from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./News.css";
import { Button, Card } from "react-bootstrap";
import setting from "../../setting.json";
import DoubleArrow from '@mui/icons-material/ArrowForward';

function News() {

    const [news, setNews] = useState();

    const getAllNews = async () => {
        await fetch(setting.api + "/api/getAllNews", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setNews(u.data);
            });
    }

    useEffect(() => {
        getAllNews();
    }, []);

    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold">Notifications</h1>
                </div>
            </header>

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                        <h2 className="mb-4 mb-lg-5"><b>News & Notifications</b></h2>
                    </div>
                    <div className="shop-cart padding-top padding-bottom">
                        <div className="container">
                            <div className="cart-checkout-box mb-widget">
                                <div >
                                    <Card
                                        // className="d-flex h-100"
                                        style={{
                                            border: "solid 2px #2f2f30",
                                            backgroundColor: "floralwhite"
                                        }}
                                    >

                                        <Card.Body>

                                            {/* <CardHeader style={{ backgroundColor: "lightgray", color: "black", textAlign: "center" }}><b>News & Notification</b></CardHeader> */}
                                            {news && news.map((n) =>

                                                <p className='text-start' style={{ borderBottom: "solid 1px", marginTop: "7px" }}>
                                                    <DoubleArrow />
                                                    {n.description}
                                                    <a href={n.description} target="_blank"><Button variant="danger" size="sm" style={{ padding: "unset", marginLeft: "4px" }}>new</Button></a>

                                                </p>

                                            )}

                                        </Card.Body>
                                    </Card>
                                </div>


                            </div>

                        </div>
                    </div >

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default News;
